const Home = () => import('@/views/Home')
const Game = () => import('@/views/Game')


const routes = [
  {
      path: "/",
      name: "index",
      redirect: "/home"
  },
  {
      path: "/home",
      name: "home",
      component: Home,
      meta:{
        pageNav: 1,
      }
  },
  {
      path: "/game",
      name: "game",
      component: Game,
      meta:{
        pageNav: 2,
      }
  }
 
]

export default new VueRouter({
  routes,
})