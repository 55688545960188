import App from './App'
import router from './router/index'
import store from './store/index'
import prototypeExpand from "@/basic/prototypeExpand"
import preventReClick from "@/basic/directive"
Vue.use(preventReClick)
Vue.use(prototypeExpand)
import './permission'

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)


Vue.prototype.$home = home
Vue.prototype.$game = game
Vue.prototype.$common = common
new Vue({
  router,
  store,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')